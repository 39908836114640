import React from 'react'
import {
  BodyContentContainer,
  MainContentContainer,
  PrimaryContainer,
  StyledTitle,
  StyledBody,
  SecondaryContainer,
  StyledImageContent,
  CtaStyled,
  StyledIcon,
  IconContainer,
  StyledDescription,
  IconGridItem,
} from './styles'
// import Fade from 'react-reveal/Fade'

const ContentWithIcons = props => {
  const {
    style,
    data: {
      primary: {
        content_section_with_icons: {
          document: {
            data: {
              cta_label,
              cta_link,
              icons,
              page_type,
              section_body,
              section_title,
              section_image,
            },
          },
        },
      },
    },
  } = props

  const title = section_title?.html?.replace('<h1>', '')?.replace('</h1>', '')

  return (
    <BodyContentContainer className="BodyContentContainer">
      <MainContentContainer>
        <PrimaryContainer style={style} className="PrimaryContainer">
          {section_title?.html ? (
            <StyledTitle
              dangerouslySetInnerHTML={{ __html: title }}
              type="h3"
              as="h3"
            />
          ) : (
            <></>
          )}
          {section_body?.html ? (
            <StyledBody
              type="primary"
              dangerouslySetInnerHTML={{
                __html: section_body.html,
              }}
            />
          ) : (
            <></>
          )}
          <IconContainer
            belowOrEqualToThree={icons?.length <= 3}
            className="IconContainer"
          >
            {icons?.length > 0 &&
              icons.map((icon, index) => {
                const { icon_image } = icon

                return (
                  <IconGridItem
                    belowOrEqualToThree={icons?.length <= 3}
                    key={index}
                  >
                    <StyledIcon
                      style={{
                        width: icon_image?.dimensions?.width
                          ? icon_image.dimensions.width
                          : null,
                        height:
                          icons?.length > 3 && icon_image?.dimensions?.height
                            ? icon_image.dimensions.height
                            : null,
                      }}
                      belowOrEqualToThree={icons?.length <= 3}
                      hasDescription={
                        icon?.icon_description?.text ? true : false
                      }
                      fluid={
                        icon?.icon_image?.localFile?.childImageSharp?.fluid
                      }
                      alt={icon?.icon_image?.alt}
                    />
                    {icon?.icon_description?.text && (
                      <StyledDescription as="div" type="primary">
                        {icon.icon_description.text}
                      </StyledDescription>
                    )}
                  </IconGridItem>
                )
              })}
          </IconContainer>
          {cta_link?.uid && cta_label?.text ? (
            <CtaStyled to={cta_link.uid} pageType={page_type}>
              {cta_label.text}
            </CtaStyled>
          ) : (
            <></>
          )}
        </PrimaryContainer>
        {section_image?.localFile?.childImageSharp?.fluid ? (
          <SecondaryContainer>
            <StyledImageContent
              fluid={section_image.localFile.childImageSharp.fluid}
              alt={section_image?.alt}
            />
          </SecondaryContainer>
        ) : (
          <></>
        )}
      </MainContentContainer>
    </BodyContentContainer>
  )
}

export default ContentWithIcons
