import styled from '@emotion/styled'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import Img from 'gatsby-image'
import StyledLink from 'components/Utilities/StyledLink'

export const BodyContentContainer = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: calc(100% - 100px);
    margin: 0px 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: calc(100% - 200px);
    margin: 0px 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: calc(100% - 376px);
    margin: 0px 186px;
  }
`

export const MainContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const PrimaryContainer = styled.div`
  width: 100%;
  margin-bottom: 70px;
  padding: 0 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 50%;
    margin: 150px 0;
  }
`

export const StyledTitle = styled(Title)`
  margin-bottom: 40px;
  font-weight: 500;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 50px;
  }
`

export const StyledBody = styled(Text)`
  font-weight: 200;

  a {
    color: ${props => props.theme.colors.primarygreen};
  }
`

export const SecondaryContainer = styled.div`
  width: 100%;
  margin: 70px 0px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 50%;
    margin: 150px 0px;
    display: flex;
    justify-content: flex-end;
  }
`

export const StyledImageContent = styled(Img)`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 500px;
    margin-top: 50px;
  }
`
export const CtaStyled = styled(StyledLink)`
  padding-top: 40px;
  font-weight: 200;
`

export const IconContainer = styled.div`
  width: 100%;
  margin: 70px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
`

export const IconGridItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  align-items: center;
  max-width: ${props =>
    props.belowOrEqualToThree ? 'calc(33% - 10px)' : 'calc(50% - 10px)'};
  margin-top: 25px;

  img {
    object-fit: ${props =>
      props.belowOrEqualToThree ? 'contain !important' : 'cover'};
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    max-width: 100%;
  }
`

export const StyledIcon = styled(Img)`
  height: 100%;
  min-width: 82px;
`

export const StyledDescription = styled(Text)`
  padding-left: 20px;
  font-weight: 200;

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    font-size: 12px;
  }

  @media (min-width: 1070px) {
    font-size: 15px;
  }

  @media (min-width: 1370px) {
    font-size: 17px;
  }

  @media (min-width: 1430px) {
    font-size: 20px;
  }
`
