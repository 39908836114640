import styled from '@emotion/styled'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import Title from 'components/Utilities/Title'
import Text from 'components/Utilities/Text'
import StyledLink from 'components/Utilities/StyledLink'

export const ProjectHighlightMainContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 2000px;
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 0px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: calc(100% - 376px);
    margin-left: 186px;
    margin-right: 186px;
  }
`

export const ProjectHiglightBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  min-height: 350px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    min-height: 650px;
  }
`

export const ProjectHighlightContentContainer = styled.div`
  background-color: ${props => props.theme.colors.lightblue};
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    position: absolute;
    top: 50%;
    left: 0px;
    height: 280px;
    width: 600px;
    transform: translateY(50%);
    padding: 30px;
  }
`

export const CtaStyled = styled(StyledLink)`
  padding-top: 40px;
  font-weight: 200;
  margin-bottom: 40px;
`

export const StyledTitle = styled(Title)`
  font-size: ${props => props.theme.fonts.xxl_medium};
  font-weight: 200;
  margin-bottom: 20px;
  margin-top: -30px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => props.theme.fonts.xxxl_medium};
    position: relative;
    top: -60px;
    margin-bottom: 0px;
  }
`

export const StyledBody = styled(Text)`
  font-weight: 200;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: -40px;
  }
`

export const ProjectHighlightImage = styled(Img)`
  width: 100%;
  min-height: 350px;
`
