import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Desktop, Mobile } from 'components/Utilities/Media'
import {
  ProjectHighlightMainContainer,
  ProjectHighlightImage,
  ProjectHiglightBackgroundImage,
  ProjectHighlightContentContainer,
  StyledTitle,
  StyledBody,
  CtaStyled,
} from './styles'

const ProjectHighlightComponent = ({ query }) => {
  return (
    <>
      <Desktop>
        <>
          {query?.fullwidth_background_image?.localFile?.childImageSharp
            ?.fluid ? (
            <ProjectHiglightBackgroundImage
              fluid={
                query?.fullwidth_background_image?.localFile?.childImageSharp
                  ?.fluid
              }
              alt={query?.fullwidth_background_image?.alt}
            >
              )
              <ProjectHighlightMainContainer>
                <ProjectHighlightContentContainer>
                  {query?.title?.text ? (
                    <Fade distance="30px" bottom>
                      <StyledTitle as="h2" type="h1">
                        {query?.title?.text}
                      </StyledTitle>
                    </Fade>
                  ) : (
                    <></>
                  )}
                  {query?.body?.text ? (
                    <Fade distance="30px" bottom>
                      <StyledBody as="p">{query?.body?.text}</StyledBody>
                    </Fade>
                  ) : (
                    <></>
                  )}
                  {query?.cta_link?.uid && query?.cta_label?.text ? (
                    <Fade distance="30px" bottom>
                      <CtaStyled
                        to={query?.cta_link?.uid}
                        pageType={query?.page_type}
                      >
                        {query?.cta_label?.text}
                      </CtaStyled>
                    </Fade>
                  ) : (
                    <></>
                  )}
                </ProjectHighlightContentContainer>
              </ProjectHighlightMainContainer>
            </ProjectHiglightBackgroundImage>
          ) : (
            <></>
          )}
        </>
      </Desktop>

      <Mobile>
        {query?.fullwidth_background_image?.localFile?.childImageSharp
          ?.fluid ? (
          <ProjectHighlightImage
            fluid={
              query?.fullwidth_background_image?.localFile?.childImageSharp
                ?.fluid
            }
            alt={query?.fullwidth_background_image?.alt}
          />
        ) : (
          <></>
        )}
        <ProjectHighlightMainContainer>
          <ProjectHighlightContentContainer>
            {query?.title?.text ? (
              <StyledTitle as="h2" type="h1">
                {query?.title?.text}
              </StyledTitle>
            ) : (
              <></>
            )}
            {query?.body?.text ? (
              <StyledBody as="p">{query?.body?.text}</StyledBody>
            ) : (
              <></>
            )}
            {query?.cta_link?.uid && query?.cta_label?.text ? (
              <CtaStyled to={query?.cta_link?.uid}>
                {query?.cta_label?.text}
              </CtaStyled>
            ) : (
              <></>
            )}
          </ProjectHighlightContentContainer>
        </ProjectHighlightMainContainer>
      </Mobile>
    </>
  )
}

ProjectHighlightComponent.propTypes = {
  query: PropTypes.object.isRequired,
}

export default ProjectHighlightComponent
